import request from './config'

// 获取巨量引擎账号列表
export function getAllAccount(params) {
  return request.get('/admin/oceanengine/account', { params })
}

// 删除巨量引擎账号
export function deleteAccount(id) {
  return request.delete(`/admin/oceanengine/account/${id}`)
}

export function updateAccount(id, params) {
  return request.put(`/admin/oceanengine/account/${id}`, params)
}

// !获取 巨量引擎列表
export function getOceanAccount(params) {
  return request.get('/admin/oeApi/accounts', { params })
}

// !修改现金基数
export function editCaseBase(id, { cashBase, novelCashBase }) {
  return request.put(`/admin/oeApi/cashBase/${id}?cashBase=${cashBase}&novelCashBase=${novelCashBase}`)
}

// 查询管家子账户

export function getOceanChannel(params) {
  return request.get('/admin/oeApi/channel', { params })
}

// 手动同步管家下子账户

export function syncOceanAccountByHand(id) {
  return request.put(`/admin/oeApi/handChannel/${id}`)
}

// 增加子账号与公众号关联

export function addOceanLinkWithChannel(params) {
  return request.put('/admin/oeApi/channel', params)
}

// 手动同步管家账号

export function syncAccount() {
  return request.post('/admin/oeApi/hand')
}
